/** @namespace Bodypwa/Util/Cookies/setCookie */
export const setCookie = (name, value, days) => {
    let expires = '';
    if (days) {
        const date = new Date();
        // eslint-disable-next-line no-magic-numbers
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = `; expires=${ date.toUTCString()}`;
    }
    document.cookie = `${name }=${ value || '' }${expires }; path=/`;
};

/** @namespace Bodypwa/Util/Cookies/getCookie */
export const getCookie = (name) => {
    const nameEQ = `${name }=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }

    return '';
};
