/**
 * Layout Updates compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace LayoutUpdates/Query/LayoutUpdates/Query */
export class LayoutUpdatesQuery {
    getQuery() {
        return new Field('layoutUpdates')
            .addFieldList(this.getChildFields());
    }

    getChildFields() {
        return [
            'page_id',
            'instance_id',
            'page_group',
            'layout_handle',
            'block_reference',
            'page_for',
            'entities',
            'page_template',
            'sort_order'
        ];
    }
}

export default new LayoutUpdatesQuery();
